import * as React from "react"
import { useContext } from "react";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Container, Row, Col, Modal, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap'
import { useCookies } from 'react-cookie';

function isBrowser() {
    return typeof window !== "undefined"
}

function getValue(key, defaultValue) {
    return isBrowser() && window.sessionStorage.getItem(key)
        ? JSON.parse(window.sessionStorage.getItem(key))
        : defaultValue
}

function setValue(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

function useStickyState(defaultValue, key) {
    const [value, setter] = React.useState(() => {
        return getValue(key, defaultValue)
    })

    React.useEffect(() => {
        setValue(key, value)
    }, [key, value])

    return [value, setter]
}

const ContextAwareToggleCustom = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
        <>
            <img src={isCurrentEventKey ? '/app-icon-subtraction.svg' : '/app-icon-addition.svg'} alt="app icon addition or subtraction" />
            <ul>
                <li className={isCurrentEventKey ? 'active' : ''}>
                    <button
                        type="button"
                        className={isCurrentEventKey ? 'active' : ''}
                        onClick={decoratedOnClick}
                    >
                        {children}
                    </button>
                </li>
        </ul>
      </>
    );
}

function CookieConsentPopUp(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="app-cookieconsent-popup"
        >
            <Modal.Header closeButton className="app-cookieconsent-popup-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    Cookies Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="app-cookieconsent-popup-body">
                <div className="app-cookieconsent-popup-content">
                    <h6 className="app-cookieconsent-popup-content-title">Privacy Preferences Center</h6>
                    <h6>When you visit any website, it may store or retrieve 
                        information on your browser, mostly in the form of cookies. 
                        This information might be about you, your preferences or your 
                        device and is mostly used to make the site work as you expect it 
                        to. The information does not usually directly identify you, but it 
                        can give you a more personalized web experience. Because we respect 
                        your right to privacy, you can choose not to allow some types of 
                        cookies. Click on the different category headings to find out more 
                        and change our default settings. However, blocking some types of 
                        cookies may impact your experience of the site and the services 
                        we are able to offer. More information</h6>
                </div>
                <div className="app-cookieconsent-popup-content">
                    <h6 className="app-cookieconsent-popup-content-title">Manage Cookie Preferences</h6>
                    <Accordion className="app-cookieconsent-popup-detail-content" defaultActiveKey="app-cookieconsent-strictly-necessary">
                        <div className="app-cookieconsent-popup-detail-content-header">
                            <div className="app-cookieconsent-popup-detail-content-header-left">
                                <ContextAwareToggleCustom
                                    eventKey="app-cookieconsent-strictly-necessary"
                                >
                                    <h6>Strictly Necessary</h6>
                                </ContextAwareToggleCustom>
                            </div>
                            <div className="app-cookieconsent-popup-detail-content-header-right">
                                <label class="app-cookieconsent-popup-switch">
                                    <input type="checkbox" />
                                    <span class="app-cookieconsent-popup-slider round">
                                        <p>On</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="app-cookieconsent-strictly-necessary">
                            <div className="app-cookieconsent-popup-detail-content-body">
                                <h6>These cookies are necessary for the website to function and cannot 
                                    be switched off in our systems. They are usually only set in response 
                                    to actions made by you which amount to a request for services, such as 
                                    setting your privacy preferences, logging in or filling in forms. You 
                                    can set your browser to block or alert you about these cookies, but some 
                                    parts of the site will not then work. These cookies do not store any 
                                    personally identifiable information.</h6>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion className="app-cookieconsent-popup-detail-content">
                        <div className="app-cookieconsent-popup-detail-content-header">
                            <div className="app-cookieconsent-popup-detail-content-header-left">
                                <ContextAwareToggleCustom
                                    eventKey="app-cookieconsent-performance-cookies"
                                >
                                    <h6>Performance Cookies</h6>
                                </ContextAwareToggleCustom>
                            </div>
                            <div className="app-cookieconsent-popup-detail-content-header-right">
                                <label class="app-cookieconsent-popup-switch">
                                    <input type="checkbox" />
                                    <span class="app-cookieconsent-popup-slider round">
                                        <p>On</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="app-cookieconsent-performance-cookies">
                            <div className="app-cookieconsent-popup-detail-content-body">
                                <h6>These cookies are necessary for the website to function and cannot 
                                    be switched off in our systems. They are usually only set in response 
                                    to actions made by you which amount to a request for services, such as 
                                    setting your privacy preferences, logging in or filling in forms. You 
                                    can set your browser to block or alert you about these cookies, but some 
                                    parts of the site will not then work. These cookies do not store any 
                                    personally identifiable information.</h6>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion className="app-cookieconsent-popup-detail-content">
                        <div className="app-cookieconsent-popup-detail-content-header">
                            <div className="app-cookieconsent-popup-detail-content-header-left">
                                <ContextAwareToggleCustom
                                    eventKey="app-cookieconsent-targeting-cookies"
                                >
                                    <h6>Targeting Cookies</h6>
                                </ContextAwareToggleCustom>
                            </div>
                            <div className="app-cookieconsent-popup-detail-content-header-right">
                                <label class="app-cookieconsent-popup-switch">
                                    <input type="checkbox" />
                                    <span class="app-cookieconsent-popup-slider round">
                                        <p>On</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="app-cookieconsent-targeting-cookies">
                            <div className="app-cookieconsent-popup-detail-content-body">
                                <h6>These cookies are necessary for the website to function and cannot 
                                    be switched off in our systems. They are usually only set in response 
                                    to actions made by you which amount to a request for services, such as 
                                    setting your privacy preferences, logging in or filling in forms. You 
                                    can set your browser to block or alert you about these cookies, but some 
                                    parts of the site will not then work. These cookies do not store any 
                                    personally identifiable information.</h6>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion className="app-cookieconsent-popup-detail-content">
                        <div className="app-cookieconsent-popup-detail-content-header">
                            <div className="app-cookieconsent-popup-detail-content-header-left">
                                <ContextAwareToggleCustom
                                    eventKey="app-cookieconsent-analytics-cookies"
                                >
                                    <h6>Analytics Cookies</h6>
                                </ContextAwareToggleCustom>
                            </div>
                            <div className="app-cookieconsent-popup-detail-content-header-right">
                                <label class="app-cookieconsent-popup-switch">
                                    <input type="checkbox" />
                                    <span class="app-cookieconsent-popup-slider round">
                                        <p>On</p>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <Accordion.Collapse eventKey="app-cookieconsent-analytics-cookies">
                            <div className="app-cookieconsent-popup-detail-content-body">
                                <h6>These cookies are necessary for the website to function and cannot 
                                    be switched off in our systems. They are usually only set in response 
                                    to actions made by you which amount to a request for services, such as 
                                    setting your privacy preferences, logging in or filling in forms. You 
                                    can set your browser to block or alert you about these cookies, but some 
                                    parts of the site will not then work. These cookies do not store any 
                                    personally identifiable information.</h6>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </div>
            </Modal.Body>
            <Modal.Footer className="app-cookieconsent-popup-footer">
                <button onClick={props.onHide}>Save</button>
            </Modal.Footer>
        </Modal>
    );
}

const CookieConsent = () => {
	const [cookies] = useCookies(['gatsby-gdpr-google-analytics']);
	var noCookie = typeof cookies['gatsby-gdpr-google-analytics'] === 'undefined' || cookies['gatsby-gdpr-google-analytics'] == null;

    const location = useLocation()
    if (isBrowser()) {
        initializeAndTrack(location)
    }

    const [bannerHidden, setBannerHidden] = useStickyState(
        false,
        "consentCookieHidden"
    )

    const enableAnalytics = () => {
        document.cookie = "gatsby-gdpr-google-analytics=true"
        setBannerHidden(true)
    }

    const declineAnalytics = () => {
        // let cookieconsentEle = document.getElementById("app-cookieconsent-wrap")
        // if (cookieconsentEle) {
        //     cookieconsentEle.remove()
        // }
        setBannerHidden(true)
    }

    const [modalShow, setModalShow] = React.useState(false);
	
    return (
        <>
			<div className="app-cookieconsent">
                {(!bannerHidden && noCookie) && (
					<div id="app-cookieconsent-wrap" className="app-cookieconsent-wrap" data-sal="fade" data-sal-duration="300">
						<Container>
							<Row>
								<Col xs={12} md={12} lg={7} xl={8}>
									<div className="app-cookieconsent-left">
										<h4>Cookies Acceptance</h4>
										<h6>
											By clicking “Accept All Cookies”, you agree to let us store cookies on your device to 
											provide better browsing experience, enhance site navigation, analyze site usage, 
											and assist in our marketing efforts. Check our <a href="/privacy-policy">Privacy Policy</a> for more cookies detail.
										</h6>
									</div>
								</Col>
								<Col className="app-cookieconsent-col-custom" xs={12} md={12} lg={5} xl={4}>
									<div className="app-cookieconsent-right">
										{/* <button className="app-cookieconsent-right-btn-setting" onClick={() => setModalShow(true)}>Cookies Settings</button> */}
										<button className="app-cookieconsent-right-btn-accept" onClick={enableAnalytics}>Accept All Cookies</button>
									</div>
								</Col>
							</Row>
						</Container>
						<img className="app-cookieconsent-img" src="/app-icon-close.svg" alt="app-icon-close" onClick={declineAnalytics} />
					</div>
				)}
			</div>
            <CookieConsentPopUp
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default CookieConsent